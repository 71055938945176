import { useEffect, useRef, useState } from "react";
import Loading from "../../../components/Loading";
import {
  Image,
  Layer,
  Rect,
  Stage,
  Text,
  Group,
  Transformer,
} from "react-konva";
import useImage from "use-image";
import { scaleCalc } from "../../../helpers/imageHelpers";
import { styled } from "styled-components";
import useDelay from "../../../hooks/useDelay";

const ImageMetaDataCanvas = ({
  rectangles = [],
  handleKeyDown = () => {},
  handleMouseDown = () => {},
  handleMouseMove = () => {},
  handleMouseUp = () => {},
  handleRectClick = () => {},
  handleRectDragStart = () => {},
  handleRectDragEnd = () => {},
  drawing = false,
  startPos = { x: 0, y: 0 },
  endPos = { x: 0, y: 0 },
  selectedId = null,
  editState = {},
  imagewidth = 700,
  bitmap = null,
  imageURL = null,
}) => {
  const [image, imageStatus] = useImage(imageURL);
  const isDelayed = useDelay(1000);

  if (!bitmap || !imageURL || imageStatus !== "loaded" || isDelayed)
    return <Loading />;

  return (
    <CanvasArea onKeyDown={(e) => handleKeyDown(e)}>
      <Stage
        width={imagewidth}
        height={(imagewidth / 4) * 3}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={(e) => handleMouseUp({ id: editState?.nextId, e })}
      >
        <Layer>
          <Image
            width={imagewidth}
            height={(imagewidth / 4) * 3}
            x={0}
            image={image}
          />
        </Layer>
        <Layer>
          {rectangles.map((rect, i) => (
            <RectangleGroup
              rect={rect}
              key={i}
              bitmap={bitmap}
              imagewidth={imagewidth}
              handleRectDragStart={handleRectDragStart}
              handleRectClick={handleRectClick}
              handleRectDragEnd={handleRectDragEnd}
              onTransformStart={(e) => handleRectDragStart(e, rect.rectangleId)}
              selectedId={selectedId}
            />
          ))}
          {drawing && (
            <Rect
              x={startPos.x}
              y={startPos.y}
              width={endPos.x - startPos.x}
              height={endPos.y - startPos.y}
              stroke="black"
            />
          )}
        </Layer>
      </Stage>
    </CanvasArea>
  );
};

const RectangleGroup = ({
  rect,
  bitmap,
  imagewidth,
  selectedId,
  handleRectDragStart,
  handleRectDragEnd,
  handleRectClick,
  onTransformStart = () => {},
}) => {
  const shapeRef = useRef();
  const trRef = useRef();
  const [textPosition, setTextPosition] = useState({ x: 6, y: 6 });

  useEffect(() => {
    if (rect.rectangleId === selectedId) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current]);
      trRef.current.getLayer().batchDraw();
    }
  }, [rect.rectangleId, selectedId]);

  useEffect(() => {
    if (shapeRef.current) {
      const groupPosition = shapeRef.current.position();
      setTextPosition({
        x: groupPosition.x + 6,
        y: groupPosition.y + 6,
      });
    }
  }, [rect]);

  return (
    <>
      <Group
        key={rect.rectangleId}
        x={scaleCalc(bitmap.width, imagewidth, rect.x, rect.scale)}
        y={scaleCalc(bitmap.width, imagewidth, rect.y, rect.scale)}
        width={scaleCalc(bitmap.width, imagewidth, rect.width, rect.scale)}
        height={scaleCalc(bitmap.width, imagewidth, rect.height, rect.scale)}
        draggable
        onDragMove={(e) => handleRectDragStart(e, rect.rectangleId)}
        onDragEnd={(e) =>
          handleRectDragEnd(
            e,
            rect.rectangleId,
            scaleCalc(bitmap.width, imagewidth, rect.width, rect.scale),
            scaleCalc(bitmap.width, imagewidth, rect.height, rect.scale)
          )
        }
      >
        <Rect
          ref={shapeRef}
          width={scaleCalc(bitmap.width, imagewidth, rect.width, rect.scale)}
          height={scaleCalc(bitmap.width, imagewidth, rect.height, rect.scale)}
          opacity={0.5}
          fill={rect.hexColor}
          onClick={(e) => handleRectClick(e, rect.rectangleId)}
        />
        <Text
          text={rect?.metaDataTag?.label ?? `id-${rect.rectangleId ?? 0}`}
          x={textPosition.x}
          y={textPosition.y}
        />
      </Group>
      {rect.rectangleId === selectedId && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          onTransformStart={onTransformStart}
          onTransformEnd={(e) => {
            const node = shapeRef.current;

            const scaleX = node.scaleX();
            const scaleY = node.scaleY();

            node.scaleX(1);
            node.scaleY(1);

            const newWidth = Math.max(5, node.width() * scaleX);
            const newHeight = Math.max(5, node.height() * scaleY);

            const item = {
              target: {
                attrs: {
                  x: scaleCalc(
                    bitmap.width,
                    imagewidth,
                    rect.x + node.x(),
                    rect.scale
                  ),
                  y: scaleCalc(
                    bitmap.width,
                    imagewidth,
                    node.y() + rect.y,
                    rect.scale
                  ),
                },
              },
            };

            handleRectDragEnd(item, rect.rectangleId, newWidth, newHeight);
          }}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox;
            }
            return newBox;
          }}
        />
      )}
    </>
  );
};

const CanvasArea = styled.div`
  .konvajs-content {
    // outer border
    margin: 0 auto;
    border: 1px solid #ddd;
  }
`;

export default ImageMetaDataCanvas;
