import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

const Loading = ({ subtitle = "Loading..." }) => {
  return (
    <Box sx={{ display: "flex", textAlign: "center", flexDirection: "column" }}>
      <div style={{ margin: "0.5rem 0 0 0" }}>
        <CircularProgress />
      </div>
      <div style={{ margin: "0.5rem 0 0.5rem 0" }}>{subtitle}</div>
    </Box>
  );
};

export default Loading;
