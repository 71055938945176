import styled from "styled-components";
import Latex from "../../../helpers/latexify";
import { LatexTable } from "../../../helpers/latexHelpers";

export const RenderQuestionImage = ({ metaData, metaDataTag }) => {
  if (metaDataTag?.label !== "Question Image") return null;

  return (
    <div style={{ textAlign: "center" }}>
      <QuestionImage
        src={URL.createObjectURL(metaData.blob)}
        alt="Question Image"
      />
    </div>
  );
};

export const RenderQuestionText = ({ metaData, metaDataTag }) => {
  if (metaDataTag?.label !== "Question Text") return null;

  return (
    <QuestionText style={{ whiteSpace: "pre-line" }}>
      {LatexTable(metaData.text) || <Latex>{metaData.text}</Latex>}
    </QuestionText>
  );
};

export const RenderAnswerText = ({ metaData, metaDataTag, question }) => {
  const answerTypes = [
    "",
    "Answer A Text",
    "Answer B Text",
    "Answer C Text",
    "Answer D Text",
  ];
  if (!answerTypes.includes(metaDataTag?.label) || !metaData.text) return null;
  return (
    <AnswerText
      iscorrect={
        answerTypes.indexOf(metaDataTag?.label) === question?.correctAnswer
      }
    >
      <AnswerLabel bgcolor={metaDataTag?.hexColor}>
        {metaDataTag?.label?.split(" ")[1] || "#"}
      </AnswerLabel>
      <div style={{ whiteSpace: "pre-line" }}>
        {metaData?.text ? <Latex>{metaData.text}</Latex> : <>-</>}
      </div>
    </AnswerText>
  );
};

export const RenderAnswerImage = ({ metaData, metaDataTag, question }) => {
  const answerTypes = [
    "",
    "Answer A Image",
    "Answer B Image",
    "Answer C Image",
    "Answer D Image",
  ];
  if (!answerTypes.includes(metaDataTag?.label)) return null;
  return (
    <AnswerText
      iscorrect={
        answerTypes.indexOf(metaDataTag?.label) === question?.correctAnswer
      }
    >
      <AnswerLabel bgcolor={metaDataTag?.hexColor}>
        {metaDataTag?.label?.split(" ")[1] || "#"}
      </AnswerLabel>
      <div>
        <AnswerImage
          src={URL.createObjectURL(metaData.blob)}
          alt="Answer Image"
        />
      </div>
    </AnswerText>
  );
};

const QuestionImage = styled.img`
  max-width: 450px;
  width: auto !important;
  height: auto !important;
  margin: 0 auto;
`;

const AnswerImage = styled.img`
  max-width: 250px;
`;

const QuestionText = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0 0 0.5rem 0;
  text-align: center;
`;

const AnswerText = styled.div`
  font-size: 1.25rem;
  font-weight: normal;
  margin: 1rem 0 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) =>
    props.iscorrect &&
    `
      background: #E0FDD6;
      padding: 0.2rem;
      border-radius: 8px;
      border: 1px solid lightgreen;
    `}

  div {
    margin: 0 0.5rem 0 0;
  }
`;

const AnswerLabel = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.bgcolor ?? "#ccc"}; /* Replace with your desired background color */
  color: #ffffff;
  text-align: center;
  font-size: 24px;
  line-height: 50px;
`;
