// supress no-useless-escape warning
/* eslint-disable no-useless-escape */
import katex from "katex";
import React from "react";

const latexify = (string, options) => {
  const regularExpression =
    /\$\$[\s\S]+?\$\$|\\\[[\s\S]+?\\\]|\\\([\s\S]+?\\\)|\$[^\$\\]*(?:\\.[^\$\\]*)*\$/g;
  const blockRegularExpression = /\$\$[\s\S]+?\$\$|\\\[[\s\S]+?\\\]/g;

  const stripDollars = (stringToStrip) =>
    stringToStrip[0] === "$" && stringToStrip[1] !== "$"
      ? stringToStrip.slice(1, -1)
      : stringToStrip.slice(2, -2);

  const getDisplay = (stringToDisplay) =>
    stringToDisplay.match(blockRegularExpression) ? "block" : "inline";

  const renderLatexString = (s, t) => {
    let renderedString;
    try {
      renderedString = katex.renderToString(
        s,
        t === "block"
          ? { ...options, displayMode: true, output: "html" }
          : options
      );
    } catch (err) {
      console.error("couldn`t convert string", s);
      return s;
    }
    return renderedString;
  };

  const result = [];
  const latexMatch = string.match(regularExpression);
  const stringWithoutLatex = string.split(regularExpression);

  if (latexMatch) {
    stringWithoutLatex.forEach((s, index) => {
      // If the string does not match a LaTeX expression, add it to the result as a text type.
      if (!latexMatch.includes(s)) {
        result.push({
          string: s,
          type: "text",
        });
      }

      // If a LaTeX match exists at the current index, add it to the result with the appropriate type.
      if (latexMatch[index]) {
        result.push({
          string: stripDollars(latexMatch[index]),
          type: getDisplay(latexMatch[index]),
        });
      }
    });
  } else {
    result.push({
      string,
      type: "text",
    });
  }

  const processResult = (resultToProcess) => {
    const newResult = resultToProcess.map((r) => {
      if (r.type === "text") {
        return r.string;
      }
      return (
        <span
          key={r.string}
          dangerouslySetInnerHTML={{
            __html: renderLatexString(r.string, r.type),
          }}
        />
      );
    });

    return newResult;
  };

  return processResult(result);
};

const Latex = ({
  children = "",
  displayMode = false,
  leqno = false,
  fleqn = false,
  throwOnError = true,
  errorColor = "#cc0000",
  macros = {},
  minRuleThickness = 0,
  colorIsTextColor = false,
  strict = "warn",
  trust = false,
  output = "html",
}) => {
  const renderUs = latexify(children, {
    displayMode,
    leqno,
    fleqn,
    throwOnError,
    errorColor,
    macros,
    minRuleThickness,
    colorIsTextColor,
    strict,
    trust,
    output,
  });

  return <span>{renderUs}</span>;
};

export default Latex;
