import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";

const useToast = () => {
  const [state, setState] = useState({
    open: false,
    message: "default",
    severity: "success",
    autoHideDuration: 6000,
    width: "100%",
  });
  const handleClose = () => setState(() => ({ ...state, open: false }));
  return {
    Toasty: () => (
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={state.open}
        autoHideDuration={state.autoHideDuration}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.severity}
          sx={{ width: state.width }}
        >
          {state.message}
        </Alert>
      </Snackbar>
    ),
    toast: ({ message, severity }) =>
      setState(() => ({ open: true, message, severity })),
  };
};

export default useToast;
