import { useState, useEffect } from "react";

const useDelay = (delay) => {
  const [isDelayed, setDelayed] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayed(false);
    }, delay);

    return () => clearTimeout(timer); // Clear timeout if the component unmounts
  }, [delay]);

  return isDelayed;
};

export default useDelay;
