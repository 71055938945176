import Client from "./client";
import settings from "../settings";

const getUri = (endpoint = null, uri = null) => {
  const formattedUri = `/${uri}`.replace(/\/+/g, "/");
  return `${endpoint}${formattedUri}`;
};

const getFlowApiUri = (uri) => getUri(settings.FLOW_API_URL, `${uri}`);

const getApiUri = (uri) => getUri(settings.API_URL, uri);

class Api {
  /* Users */
  static async signIn(username, password) {
    const params = {
      username,
      password,
    };
    return Client.request("POST", getApiUri("v3/tokens/admin-refresh"), params);
  }

  static async getQuestionMetadata(questionId) {
    return Client.request(
      "GET",
      getApiUri(`v4/admin/questions/${questionId}/metadata`)
    );
  }

  static async getMetadataTags() {
    return Client.request("GET", getApiUri(`v4/admin/metadata-tags`));
  }

  /* Metadata */

  /**
   * Fetches the SignalR connection info.
   * @param {*} userId
   * @param {*} hubName
   * @param {*} userRole
   * @returns
   */
  static async getSignalRConnectionInfo(userId, hubName, userRole) {
    return Client.request(
      "GET",
      getFlowApiUri(`/${hubName}/negotiate`),
      {},
      {
        headers: {
          "x-ms-signalr-userid": userId,
          userRole: userRole || "Student",
        },
      }
    );
  }

  /* Utility */
  static async request(method, url, params = null) {
    return Client.request(method, getApiUri(url), params);
  }

  static getApiUri(endpoint = null, uri = null) {
    return getApiUri(endpoint, uri);
  }
}

export default Api;
