import { Modal, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Loading from "../../../components/Loading";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#fff",
    border: "2px solid #000",
    boxShadow: null,
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
  },
}));

const LoadingModal = ({
  open,
  onClose = () => {},
  title = "Saving all...",
  subTitle = "Please wait.",
}) => {
  const classes = useStyles();

  return (
    <Modal className={classes.modal} open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        <h2 style={{ margin: "0 auto" }}>{title}</h2>
        <div style={{ width: "100%" }}>
          <Loading subtitle={subTitle} />
        </div>
      </Paper>
    </Modal>
  );
};

export default LoadingModal;
