import { useCallback, useEffect, useState } from "react";
import Api from "../api/api";
import { cropImage, loadImageBitmap } from "../helpers/imageHelpers";
import jwtDecode from "jwt-decode";
import useQuery from "./useQuery";

const useGetQuestionMetaData = ({ questionId }) => {
  const token = useQuery().get("token") ?? "";
  const [loading, setLoading] = useState(true);
  const [metaData, setMetaData] = useState([]);
  const [metaDataTags, setMetaDataTags] = useState([]);
  const [question, setQuestion] = useState({});
  const [imageURL, setImageURL] = useState(null);
  const [bitmap, setBitmap] = useState(null);

  const fetchMetaDataTags = useCallback(async () => {
    if (metaDataTags?.length || !loading) return;

    try {
      const fetchedMetaDataTags = await Api.getMetadataTags();
      setMetaDataTags(fetchedMetaDataTags);
    } catch (e) {
      console.error(e);
      throw new Error("Error fetching metadata tags");
    }
  }, [loading, metaDataTags?.length]);

  const fetchQuestionMetadata = useCallback(async () => {
    if (!questionId || metaData?.length || !loading) return;

    try {
      let userId = null;
      let claimQuestionId = null;
      let isAdmin = false;

      if (token) {
        const claims = jwtDecode(token);
        userId = claims.UserId;
        claimQuestionId = parseInt(claims.questionId, 10);
        // microsoft user role claim
        const userRoles =
          claims[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
          ];
        isAdmin = Array.isArray(userRoles) && userRoles.includes("Admin");
      }

      if (
        parseInt(claimQuestionId, 10) !== parseInt(questionId, 10) &&
        !isAdmin
      ) {
        throw new Error("Unauthorized!");
      }

      const fetchedQuestionMetaData = await Api.getQuestionMetadata(questionId);

      if (!fetchedQuestionMetaData) throw new Error("No question found");

      const imageURL =
        "https://diagnosticquestionsprod.blob.core.windows.net" +
        fetchedQuestionMetaData.imageURL;
      const bitmap = await loadImageBitmap(imageURL);

      const fetchedMetaDatas = fetchedQuestionMetaData.questionMetaDatas.filter(
        (q) => isAdmin || parseInt(q.userId, 10) === parseInt(userId, 10)
      );

      fetchedMetaDatas.forEach(async (m) => {
        if (m.blob) return m;
        m.blob = await cropImage({
          bitmap,
          x: m.x,
          y: m.y,
          width: m.width,
          height: m.height,
          scale: m.scale,
        });
        m.rectangleId = m.id ?? m.metaDataId;
        m.opacity = 0.5;
        m.hexColor = m.metaDataTag?.hexColor ?? "#DC143C";
        return m;
      });

      setMetaData(fetchedMetaDatas);
      setQuestion(() => ({
        questionId: fetchedQuestionMetaData.questionId,
        correctAnswer: fetchedQuestionMetaData.correctAnswer,
        imageURL,
      }));
      setImageURL(imageURL);
      setBitmap(bitmap);
    } catch (e) {
      console.error(e);
      throw new Error("Error fetching question metadata");
    }
  }, [loading, metaData?.length, questionId, token]);

  useEffect(() => {
    const runEffect = async () => {
      if (metaData?.length || metaDataTags?.length || !loading) return;
      await fetchMetaDataTags();
      await fetchQuestionMetadata();
      setLoading(false);
    };

    runEffect();
  }, [
    fetchMetaDataTags,
    fetchQuestionMetadata,
    loading,
    metaData?.length,
    metaDataTags?.length,
  ]);

  return {
    loading,
    metaDataTags,
    metaData,
    setMetaData,
    setMetaDataTags,
    question,
    bitmap,
    imageURL,
  };
};

export default useGetQuestionMetaData;
