const minQuestionTags = [
  "Answer A",
  "Answer B",
  "Answer C",
  "Answer D",
  "Question",
];

export const validateMetaData = (metaData, metaDataTags) => {
  if (!metaData) metaData = [];
  if (!metaDataTags) metaDataTags = [];
  const errors = [];
  metaData.forEach((m, i) => {
    if (!m.text)
      errors.push(`Text is missing for metadata ${i + 1} - run OCR.`);
    if (!m.metaDataTagId) errors.push(`Tag is missing for metadata ${i + 1}`);
  });
  minQuestionTags.forEach((t) => {
    // using the metaDataTagId from the metaData, find the metaDataTag
    const filteredTags = metaDataTags.filter((m) => m.label.includes(t));
    if (!filteredTags?.length) return;
    const metaDataTagIds = filteredTags.map((m) => m.metaDataTagId);
    if (!metaData.find((m) => metaDataTagIds.includes(m.metaDataTagId)))
      errors.push(`Missing ${t} metadata - please add a tag.`);
  });
  return errors;
};
