import Loading from "../../../components/Loading";
import styled from "styled-components";
import ImageMetaDataCanvas from "./ImageMetaDataCanvas";
import React, { useCallback, useEffect, useState } from "react";
import { cropImage } from "../../../helpers/imageHelpers";
import MetaDataItem from "./MetaDataItem";
import { validateMetaData } from "../../../helpers/validationHelpers";
import { Alert } from "@mui/material";

const ImageMetaDataShow = ({
  defaultImageWidth = 700,
  isLoading = true,
  metaData = [],
  metaDataTags = [],
  editState = { imageURL: null, bitmap: null, isLoaded: false },
  insertMetaData = () => {},
  updateMetaData = () => {},
  handleDeletePress = () => {},
  setMetaData = () => {},
}) => {
  const [drawing, setDrawing] = useState(false);
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [endPos, setEndPos] = useState({ x: 0, y: 0 });
  const [selectedRectangleId, setSelectedRectangleId] = useState(null);

  const handleKeyDown = useCallback((e) => {
    if (e.keyCode === 27) {
      setSelectedRectangleId(null);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  const handleMouseDown = (e) => {
    const isClickingOnEmpty = !e.target.parent?.attrs?.x && !e.target.attrs?.y;
    if (isClickingOnEmpty) {
      setSelectedRectangleId(null);
    }
    if (selectedRectangleId || !isClickingOnEmpty) {
      setDrawing(false);
    }
    if ((drawing || selectedRectangleId) && !isClickingOnEmpty) return;
    setDrawing(true);
    const pos = e.target.getStage().getPointerPosition();
    setStartPos(pos);
    setEndPos(pos);
  };

  const handleMouseMove = (e) => {
    if (!drawing) return;
    const pos = e.target.getStage().getPointerPosition();
    setEndPos(pos);
  };

  const generateId = (id) =>
    `${id ? id : ""}${id !== null ? "-" : ""}${
      metaData.filter((r) => (id !== null ? r.id.includes(id) : true)).length +
      1
    }`;

  const handleMouseUp = async ({ id = null, e }) => {
    if (!drawing) return;
    const rectangleId = generateId(id);
    setDrawing(false);

    const width = endPos.x - startPos.x;
    const height = endPos.y - startPos.y;

    if (width <= 20 || height <= 20) return;

    const scale = defaultImageWidth / editState.bitmap.width;

    const newMetaData = {
      x: startPos.x,
      y: startPos.y,
      width,
      height,
      rectangleId,
      hexColor: "#DC143C",
      opacity: 0.5,
      textType: "None",
      sourceWidth: editState.bitmap.width,
      sourceHeight: editState.bitmap.height,
      sourceImageURL: editState.imageURL,
      scale,
      isStale: true,
      sequence: metaData.length + 1,
    };

    newMetaData.blob = await cropImage({
      ...newMetaData,
      bitmap: editState.bitmap,
    });

    setMetaData(
      [...metaData, newMetaData].filter((s) => s.width > 30 && s.height > 30)
    );

    setSelectedRectangleId(rectangleId);
  };

  const handleRectClick = (e, rectangleId) => {
    setDrawing(false);
    e.cancelBubble = true;
    setSelectedRectangleId(
      selectedRectangleId === rectangleId ||
        !metaData.find((m) => m.rectangleId === rectangleId)
        ? null
        : rectangleId
    );
  };

  const handleRectDragStart = (e, rectangleId) => {
    setDrawing(false);
  };

  const handleRectDragEnd = async (e, rectangleId, width, height) => {
    const { x, y } = e.target.attrs;
    const scale = defaultImageWidth / editState.bitmap.width;

    const updatedMetaData = metaData.map((s) => {
      if (s.rectangleId === rectangleId) {
        const updated = {
          ...s,
          x,
          y,
          width,
          height,
          scale,
          isStale: true,
        };
        return updated;
      }
      return s;
    });

    const indexOfUpdated = updatedMetaData.findIndex(
      (s) => s.rectangleId === rectangleId
    );

    if (indexOfUpdated !== -1) {
      updatedMetaData[indexOfUpdated].blob = await cropImage({
        ...updatedMetaData[indexOfUpdated],
        bitmap: editState.bitmap,
      });
    }

    setMetaData(updatedMetaData);
  };

  const onEditMetaData = useCallback(
    async (index, propertyName, value) => {
      console.log(index, propertyName, value);
      const metaDataCopy = [...metaData];
      metaDataCopy[index][propertyName] = value;
      metaDataCopy[index].isStale = true;
      setMetaData(metaDataCopy);
    },
    [metaData, setMetaData]
  );

  if (isLoading) return <Loading />;

  const validationErrors = validateMetaData(metaData, metaDataTags);

  return (
    <Root>
      <Left>
        {metaData.map((m, i) => (
          <MetaDataItem
            key={i}
            index={i}
            metaData={m}
            metaDataTags={metaDataTags}
            onEditMetaData={onEditMetaData}
            handleDeletePress={handleDeletePress}
            insertMetaData={insertMetaData}
            updateMetaData={updateMetaData}
            selectedRectangleId={selectedRectangleId}
          />
        ))}
      </Left>
      <Right>
        <ImageMetaDataCanvas
          rectangles={metaData}
          drawing={drawing}
          startPos={startPos}
          endPos={endPos}
          selectedId={selectedRectangleId}
          handleMouseDown={handleMouseDown}
          handleMouseMove={handleMouseMove}
          handleMouseUp={handleMouseUp}
          handleRectClick={handleRectClick}
          handleRectDragStart={handleRectDragStart}
          handleRectDragEnd={handleRectDragEnd}
          editState={editState}
          imagewidth={defaultImageWidth}
          imageURL={editState.imageURL}
          bitmap={editState.bitmap}
        />
        <div
          style={{
            overflowY: "scroll",
            maxHeight: "150px",
            margin: "0.5rem 0 0 0",
          }}
        >
          {!!validationErrors.length ? (
            validationErrors.map((m, i) => (
              <Alert
                severity="warning"
                key={i}
                style={{ margin: "0 0 0.1rem 0" }}
              >
                {m}
              </Alert>
            ))
          ) : (
            <Alert severity="success">
              No validation errors found! Preview the question then save when
              ready.
            </Alert>
          )}
        </div>
      </Right>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  flex: 1;
  width: 50%;
  overflow-y: scroll;
  height: calc(100vh - 54px);
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    order: 2;
  }
`;

const Right = styled.div`
  flex: 1;
  width: 50%;
  padding: 0.25rem;
  @media (max-width: 768px) {
    width: 100%;
    height: auto;
    order: 1;
    border-bottom: 1px solid #ccc;
    align-content: center;
  }
`;

export default ImageMetaDataShow;
