import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "styled-components";
import SaveIcon from "@mui/icons-material/Save";
import Latex from "../../../helpers/latexify";
import { LatexTable, isLatexTable } from "../../../helpers/latexHelpers";
import RateReviewIcon from "@mui/icons-material/RateReview";
import MetaDataReviewModal from "./MetaDataReviewModal";
import { useState } from "react";

const textTypes = [
  { value: "None", label: "None" },
  { value: "PlainText", label: "Plain Text" },
  { value: "LaTeX", label: "LaTeX" },
  { value: "Markdown", label: "Markdown" },
];

const MetaDataItem = ({
  index,
  metaData,
  metaDataTags,
  onEditMetaData,
  selectedRectangleId,
  handleDeletePress,
  insertMetaData,
  updateMetaData,
  ...props
}) => {
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  return (
    <MetaDataWrapper
      {...props}
      isselected={`${metaData.rectangleId === selectedRectangleId}`}
      color={metaData.hexColor}
    >
      <div>
        <Typography
          variant="h6"
          style={{
            margin: "0 0.5rem 0 0.5rem",
            fontSize: "14px",
            color: "#333",
          }}
        >
          #metadata {metaData.sequence}
        </Typography>
      </div>
      <StyledFormGroup>
        <TextField
          name="sequence"
          type="number"
          value={metaData.sequence ?? 0}
          label="seq"
          style={{ width: "70px", margin: "0 0.5rem 0 0.5rem" }}
          onChange={(e) => onEditMetaData(index, "sequence", e.target.value)}
          inputProps={{ min: 0 }}
        />
        <StyledSelectFormControl>
          <InputLabel>Text Type</InputLabel>
          <Select
            value={metaData.textType ?? ""}
            onChange={(e) => onEditMetaData(index, "textType", e.target.value)}
            variant="filled"
            style={{ display: "flex", flexdirection: "column" }}
          >
            {textTypes.map((t, i) => (
              <MenuItem key={i} value={t.value}>
                {t.label}
              </MenuItem>
            ))}
          </Select>
        </StyledSelectFormControl>
        <StyledSelectFormControl style={{ minWidth: "180px" }}>
          <InputLabel>Metadata tag</InputLabel>
          <Select
            value={metaData.metaDataTagId ?? ""}
            onChange={(e) => {
              onEditMetaData(index, "metaDataTagId", e.target.value);
              onEditMetaData(
                index,
                "hexColor",
                metaDataTags?.find((t) => t.metaDataTagId === e.target.value)
                  ?.hexColor || "#DC143C"
              );
            }}
            variant="filled"
          >
            {metaDataTags?.map((t, i) => (
              <StyledMenuItem key={i} value={t.metaDataTagId}>
                <ColorBlock blockcolor={t.hexColor} />
                {t.label}
              </StyledMenuItem>
            ))}
          </Select>
        </StyledSelectFormControl>
      </StyledFormGroup>
      <StyledFormGroup>
        <StyledTextField
          multiline
          style={{ margin: "0 0.5rem 0 0.5rem" }}
          value={metaData.text || ""}
          onChange={(e) => onEditMetaData(index, "text", e.target.value)}
        />
      </StyledFormGroup>
      <StyledMetaData>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <MetaDataText>
            {metaData.blob && (
              <MetaDataImg
                src={URL.createObjectURL(metaData.blob)}
                alt={metaData.text}
              />
            )}
            {!!metaData.text && (
              <div style={{ whiteSpace: "pre-line" }}>
                {metaData.textType === "LaTeX" &&
                !isLatexTable(metaData.text) ? (
                  <Latex output="mathml" displayMode={false}>
                    {metaData.text}
                  </Latex>
                ) : isLatexTable(metaData.text) ? (
                  LatexTable(metaData.text)
                ) : (
                  <span>{metaData.text}</span>
                )}
              </div>
            )}
          </MetaDataText>
        </div>
        <MetaDataControlButtons>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={() => handleDeletePress(metaData)}
            startIcon={<SaveIcon />}
            style={{ margin: "0 0 0.5rem 0" }}
          >
            Delete
          </Button>
          <Button
            variant="contained"
            color="secondary"
            size="medium"
            startIcon={<RateReviewIcon />}
            onClick={() => setIsReviewModalOpen(true)}
          >
            Add Comment
          </Button>
        </MetaDataControlButtons>
        <MetaDataReviewModal
          open={isReviewModalOpen}
          onClose={() => setIsReviewModalOpen(false)}
          metaData={metaData}
          metaDataTag={metaDataTags?.find(
            (t) => t.metaDataTagId === metaData.metaDataTagId
          )}
          onChange={onEditMetaData}
          index={index}
        />
      </StyledMetaData>
    </MetaDataWrapper>
  );
};

const MetaDataText = styled.div`
  display: flex;
  flex-direction: column;
  background: #eee;
  padding: 0.5rem;
  border: 1px solid #ddd;
  margin: 0 1rem 0 0;
`;

const MetaDataImg = styled.img`
  max-width: 320px;
  height: auto;
`;

const StyledSelectFormControl = styled(FormControl)`
  margin: 0 0.5rem 0 0.5rem;
  min-width: 150px;
  .MuiSelect-root {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: flex-start;
  }
`;

const StyledFormGroup = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem 0 0.5rem 0;
  align-content: center;
  align-items: flex-end;
  * {
    margin-right: 0.5rem;
  }
`;

const MetaDataWrapper = styled.div`
  ${(props) =>
    props.isselected === "true" && !!props.color
      ? `
      background: ${props.color}3a;
  `
      : null};

  border-bottom: 3px solid #555;

  :hover {
    ${(props) =>
      !!props.color
        ? `
        background: ${props.color}3a;
    `
        : null};
  }
`;

const StyledMetaData = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0.5rem;
  strong {
    margin: 0 0.5rem 0 0;
  }
`;

const StyledTextField = styled(TextField)`
  flex-grow: 1;
`;

const ColorBlock = styled.div`
  width: 10px;
  height: 20px;
  background: ${(props) => props.blockcolor ?? "#DC143C"};
  margin: 0 0.5rem 0 0;
`;

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: flex-start;
`;

const MetaDataControlButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
`;

export default MetaDataItem;
