import styled from "styled-components";
import Latex from "./latexify";

function latexToHtmlTable(latexTable) {
  const regex = /\\begin{tabular}{([^}]+)}([^]*?)\\end{tabular}/s;
  const match = latexTable.match(regex);

  if (!match) {
    return [];
  }

  // regex to identity \rowcolor{<any color>} and \cellcolor{<any color>}
  const cellColorRegex = /\\cellcolor{([^}]+)}/;
  const rowColorRegex = /\\rowcolor{([^}]+)}/;

  const rows = match[2]
    // Remove first instance of \hline and last instance of \hline
    .replace("\\hline", "")
    .split("\\hline")
    .filter((s) => s?.length)
    .map((s) => s.replace("\\\\", "").split(" & "))
    // Remove empty rows or rows with just \n or \r using regex
    .map((row) => row.map((cell) => cell.replace(/[\n\r]/g, "").trim()))
    .filter((row) => row.filter((cell) => cell.length).length);

  console.log(rows, match);

  // max array length
  const max = rows.reduce((acc, row) => Math.max(acc, row.length), 0);

  // pad all rows to max length
  return rows.map((row) => {
    while (row.length < max) {
      row.push("");
    }
    let colorMatch = row.map((r) => r.match(rowColorRegex)).find((r) => r);

    row = row.map((cell) => {
      const background = colorMatch?.length
        ? colorMatch[1]
        : cell.match(cellColorRegex)
        ? cell.match(cellColorRegex)[1]
        : null;

      return {
        cell: background
          ? cell.replace(rowColorRegex, "").replace(cellColorRegex, "")
          : cell,
        background,
      };
    });
    return row;
  });
}

const isLatexTable = (latex) => latex?.includes("\\begin{tabular}");

function LatexTable(latex) {
  if (!isLatexTable(latex)) return null;

  const rows = latexToHtmlTable(latex);

  if (!rows.length) return null;

  const max = rows.reduce((acc, row) => Math.max(acc, row.length), 0);
  const width = `${100 / max}%`;

  return (
    <Table border="1" cellSpacing="0" cellPadding="5" width={width}>
      <tbody>
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {row.map((r, cellIndex) => (
              <td key={cellIndex} style={{ background: r.background }}>
                <Latex output="mathml" displayMode={false}>
                  {r.cell}
                </Latex>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

const Table = styled.table`
  width: 100%;
  max-width: 500px;
  table-layout: fixed;
  border-collapse: collapse;
  margin: 0 auto;
  th,
  td {
    width: ${(props) => (props.width ? props.width : "33.33%")};
    border: 1px solid black;
    padding: 5px;
    text-align: center;
    vertical-align: middle;
  }
`;

export { latexToHtmlTable, LatexTable, isLatexTable };
