import { Modal, Paper, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "fff",
    border: "2px solid #000",
    boxShadow: null,
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    minWidth: "500px",
  },
}));

const HelpModal = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Modal className={classes.modal} open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        <div>
          <Typography
            variant="h4"
            style={{ textAlign: "center", width: "100%" }}
          >
            Help
          </Typography>
        </div>
        <div>
          <p>
            Instructions can be found here:
            <br />
            <a
              href="https://eedi.notion.site/eedi/MTurk-Question-Extract-Instructions-1ed2519d522643a68377585bbd142db2"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://eedi.notion.site/eedi/MTurk-Question-Extract-Instructions-1ed2519d522643a68377585bbd142db2
            </a>
          </p>
        </div>
      </Paper>
    </Modal>
  );
};

export default HelpModal;
