import settings from "../settings";

const fetchMathpixText = async ({
  blob,
  options = {
    formats: ["text"],
    include_line_data: true,
    rm_spaces: true,
    numbers_default_to_math: true,
  },
}) => {
  const headers = {
    "Content-Type": "application/json",
    app_id: settings.MATHPIX_API_KEY,
    app_key: settings.MATHPIX_API_KEY,
  };

  const response = await fetch("https://api.mathpix.com/v3/text", {
    method: "POST",
    headers,
    body: JSON.stringify({ src: await getDataUrl(blob), ...options }),
  });

  return await response.json();
};

const getDataUrl = async (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export default fetchMathpixText;
