import Api from "../../api/api";
import { useCallback, useEffect, useState } from "react";
import Loading from "../../components/Loading";
import styled from "styled-components";
import { detectROIs } from "../../helpers/imageHelpers";
import ImageMetaDataShow from "./components/ImageMetaDataShow";
import {
  Button,
  TextField,
  Input,
  Chip,
  FormControl,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import PageviewIcon from "@mui/icons-material/Pageview";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import ReceiptIcon from "@mui/icons-material/Receipt";
import fetchMathpixText from "../../api/mathPixApi";
import { makeStyles } from "@mui/styles";
import PreviewQuestionModal from "./components/PreviewQuestionModal";
import { ImageSearch, QuestionMarkOutlined } from "@mui/icons-material";
import useQuery from "../../hooks/useQuery";
import SaveIcon from "@mui/icons-material/Save";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import LoadingModal from "./components/LoadingModal";
import { useParams } from "react-router";
import FunctionalQuestionModal from "./components/FunctionalQuestionModal";
import useToast from "../../components/Toast";
import useGetQuestionMetaData from "../../hooks/useGetQuestionMetaData";
import DeleteModal from "./components/DeleteModal";
import { tryParseInt } from "../../helpers/numberHelpers";
import { validateMetaData } from "../../helpers/validationHelpers";
import HelpModal from "./components/HelpModal";
import settings from "../../settings";

const useStyles = makeStyles(() => ({
  textInput: {
    margin: "0 0.5rem 0 0",
    width: "130px",
  },
}));

const roiDefaults = {
  threshold: 1,
  rectangleProximityThreshold: 5,
  expandPixels: 12,
  cropTopPixels: 80,
  cropBottomPixels: 80,
  minPixels: 60,
};

const QuestionExtractorPage = () => {
  const isSandbox = !!useQuery().get("sandbox");
  const mturkPostUrl = isSandbox
    ? "https://workersandbox.mturk.com/mturk/externalSubmit"
    : "https://www.mturk.com/mturk/externalSubmit";
  const assignmentId = useQuery().get("assignmentId");
  const assignment_id = useQuery().get("assignment_id");
  const { questionId } = useParams();
  const isAdvancedMode = !!useQuery().get("advancedMode");
  const classes = useStyles();
  const customWidth = tryParseInt(useQuery().get("imageWidth"));
  const defaultImageWidth = customWidth > 0 ? customWidth : 900;
  const { toast, Toasty } = useToast();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const {
    loading: loadingQuestion,
    metaDataTags,
    metaData,
    setMetaData,
    question,
    imageURL,
    bitmap,
  } = useGetQuestionMetaData({
    questionId,
  });
  const [editState, setEditState] = useState({
    isLoaded: false,
    bitmap,
    imageURL,
    isDeleteModalOpen: false,
    sortDirection: "asc",
    sortBy: "sequence",
    ...roiDefaults,
  });
  const [isSavingModalOpen, setIsSavingModalOpen] = useState(false);
  const [isFunctionalQuestionModalOpen, setIsFunctionalQuestionModalOpen] =
    useState(false);

  const getSortedMetaData = useCallback(
    () =>
      editState?.sortDirection === "desc"
        ? metaData.sort((a, b) =>
            editState?.sortBy?.length > 0
              ? b[editState?.sortBy] > a[editState?.sortBy]
                ? 1
                : -1
              : 0
          )
        : metaData.sort((a, b) =>
            editState?.sortBy?.length > 0
              ? a[editState?.sortBy] > b[editState?.sortBy]
                ? 1
                : -1
              : 0
          ),
    [editState?.sortBy, editState?.sortDirection, metaData]
  );

  const runAnalyse = useCallback(async () => {
    setEditState({ ...editState, isAnalysing: true });
    const scale =
      defaultImageWidth / editState?.bitmap?.width ?? defaultImageWidth;
    const metaData = await detectROIs({ ...editState, scale });

    setMetaData(
      metaData.map((m) => ({
        ...m,
        sourceWidth: editState?.bitmap?.width,
        sourceHeight: editState?.bitmap?.height,
        scale: 1,
        sourceImageURL: editState?.imageURL,
      }))
    );
    setEditState({ ...editState, isAnalysing: false });
  }, [defaultImageWidth, editState, setMetaData]);

  const runMathPix = useCallback(async () => {
    setEditState({ ...editState, isAnalysing: true });
    try {
      const inputs = metaData.filter((r) => r.blob);
      inputs.forEach(async (input) => {
        const result = await fetchMathpixText({ blob: input.blob });
        setMetaData(
          [...metaData].map((m) => {
            if (m.rectangleId === input.rectangleId) {
              m.text = result?.text ?? m.text;
              m.textType = result?.text ? "LaTeX" : m.textType;
              if (m.text) {
                m.isStale = true;
              }
            }
            return m;
          })
        );
      });
    } catch (e) {
      toast(e.toString(), "error");
      console.error(e);
    }
    setEditState({ ...editState, isAnalysing: false });
  }, [editState, metaData, setMetaData, toast]);

  const deleteMetaData = async (metaDataId) => {
    const metaDataIdToDelete =
      editState.metaDataToDelete.metaDataId ?? metaDataId;

    if (!metaDataIdToDelete) return;

    try {
      await Api.request(
        "DELETE",
        `v4/admin/questions/${questionId}/metadata/${metaDataIdToDelete}`
      );

      setMetaData(metaData.filter((m) => m.metaDataId !== metaDataIdToDelete));

      setEditState({
        ...editState,
        isDeleteModalOpen: false,
        metaDataToDelete: null,
      });
      toast({ message: "Deleted MetaData", severity: "success" });
    } catch (e) {
      toast({ message: e.toString(), severity: "error" });
    }
  };

  const handleDeletePress = async (metaDataToDelete) => {
    try {
      if (!metaDataToDelete?.metaDataId) {
        setMetaData(
          metaData
            .filter((m) => m.rectangleId !== metaDataToDelete.rectangleId)
            .sort((a, b) => a.sequence - b.sequence)
            .map((m, i) => ({
              ...m,
              rectangleId: m.id ? m.id : i,
              sequence: m.id ? m.sequence : i + 1,
            }))
        );
        return;
      }
      setEditState({
        ...editState,
        isDeleteModalOpen: true,
        metaDataToDelete,
      });
    } catch (e) {
      console.error("delete error?", e);
    }
  };

  const insertMetaData = async (metaDataInsert) => {
    if (!!metaDataInsert.metaDataId) return;

    const scaleFactor = 1.0 / metaDataInsert.scale;
    metaDataInsert.x = Math.round(metaDataInsert.x * scaleFactor);
    metaDataInsert.y = Math.round(metaDataInsert.y * scaleFactor);
    metaDataInsert.width = Math.round(metaDataInsert.width * scaleFactor);
    metaDataInsert.height = Math.round(metaDataInsert.height * scaleFactor);
    metaDataInsert.scale = 1;

    setEditState(() => ({ ...editState, isSaving: true }));

    try {
      const result = await Api.request(
        "POST",
        `v4/admin/questions/${questionId}/metadata`,
        metaDataInsert
      );

      const metaDataResult = result.questionMetaDatas
        .filter((f) => !metaData?.find((m) => m.metaDataId === f.metaDataId))
        .map((m) => ({
          ...m,
          rectangleId: m.id,
          opacity: 0.5,
          hexColor: m.metaDataTag?.hexColor ?? "#DC143C",
        }))[0];

      const metaDataCopy = [...metaData];
      const index = metaDataCopy.findIndex(
        (i) => i.rectangleId === metaDataInsert.rectangleId
      );
      metaDataCopy[index] = {
        ...metaDataCopy[index],
        ...metaDataResult,
        isStale: false,
      };
      setMetaData(metaDataCopy);
      toast({ message: "Inserted MetaData", severity: "success" });
    } catch (e) {
      toast({ message: e.toString(), severity: "error" });
      console.error(e);
    }

    setEditState(() => ({ ...editState, isSaving: false }));
  };

  const updateMetaData = async (metaDataUpdate) => {
    if (!metaDataUpdate.metaDataId) return;

    setEditState(() => ({ ...editState, isSaving: true }));

    const scaleFactor = 1.0 / metaDataUpdate.scale;
    metaDataUpdate.x = Math.round(metaDataUpdate.x * scaleFactor);
    metaDataUpdate.y = Math.round(metaDataUpdate.y * scaleFactor);
    metaDataUpdate.width = Math.round(metaDataUpdate.width * scaleFactor);
    metaDataUpdate.height = Math.round(metaDataUpdate.height * scaleFactor);
    metaDataUpdate.scale = 1;

    try {
      const result = await Api.request(
        "PUT",
        `v4/admin/questions/${questionId}/metadata/${metaDataUpdate.metaDataId}`,
        metaDataUpdate
      );

      const resultMetaData = result.questionMetaDatas.find(
        (m) => m.metaDataId === metaDataUpdate.metaDataId
      );

      if (!resultMetaData) return;

      const metaDataCopy = [...metaData];
      const index = metaDataCopy.findIndex(
        (i) => i.metaDataId === resultMetaData.metaDataId
      );
      metaDataCopy[index] = {
        ...metaDataCopy[index],
        ...resultMetaData,
        rectangleId: resultMetaData.id,
        opacity: 0.5,
        hexColor: resultMetaData.metaDataTag?.hexColor ?? "#DC143C",
        isStale: false,
      };
      setMetaData(metaDataCopy);
      toast({ message: "Updated MetaData", severity: "success" });
    } catch (e) {
      toast({ message: e.toString(), severity: "error" });
    }

    setEditState(() => ({ ...editState, isSaving: false }));
  };

  const saveAllMetaData = async (event) => {
    setIsSavingModalOpen(true);
    const metaDataToSave = metaData.filter((m) => m.isStale);

    if (metaDataToSave.length > 0) {
      for (const m of metaDataToSave.sort((a, b) => a.sequence - b.sequence)) {
        if (!m.metaDataId) {
          await insertMetaData(m);
        } else {
          await updateMetaData(m);
        }
      }
    }

    if (metaDataToSave.length > 1) window.location.reload();
    setIsSavingModalOpen(false);
  };

  useEffect(() => {
    if (!editState.isLoaded && !loadingQuestion) {
      setEditState({
        ...editState,
        isLoaded: true,
        bitmap,
        imageURL,
      });

      if (
        metaData.length > 0 &&
        !!validateMetaData(metaData, metaDataTags) &&
        metaData.filter((m) => m.metaDataId).length === metaData.length
      ) {
        setIsSaved(true);
      }
    }
  }, [metaData, editState, loadingQuestion, bitmap, imageURL, metaDataTags]);

  if (!editState.isLoaded || loadingQuestion) return <Loading />;

  const validationErrors = validateMetaData(metaData, metaDataTags);

  return (
    <StyledPage>
      <QuestionInfo>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <div>
            <Button
              variant="contained"
              color="primary"
              startIcon={<ReceiptIcon />}
              disabled={metaData.length === 0 || editState.isAnalysing}
              onClick={runMathPix}
            >
              OCR
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              disabled={metaData.length === 0 || editState.isAnalysing}
              startIcon={
                editState.sortDirection === "asc" ? (
                  <ArrowDownwardIcon />
                ) : (
                  <ArrowUpwardIcon />
                )
              }
              onClick={() => {
                setEditState(() => ({
                  ...editState,
                  sortDirection:
                    editState.sortDirection === "asc" ? "desc" : "asc",
                }));
                setMetaData(metaData);
              }}
            >
              Sort
            </Button>
          </div>
          <div>
            <FormControl>
              <Select
                disabled={metaData.length === 0 || editState.isAnalysing}
                value={editState.sortBy}
                style={{ width: "200px" }}
                onChange={(e) =>
                  setEditState(() => ({ ...editState, sortBy: e.target.value }))
                }
                input={<Input />}
                renderValue={(selected) => (
                  <div>
                    <Chip key={selected} label={selected} />
                  </div>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 30 * 4.5 + 8,
                      width: 250,
                    },
                  },
                }}
              >
                {[
                  "sequence",
                  "id",
                  "metaDataId",
                  "x",
                  "y",
                  "width",
                  "height",
                  "rectangleId",
                ].map((name) => (
                  <MenuItem key={name} value={name}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div>
            <p style={{ color: "#ccc", fontSize: "14px" }}>
              {settings.ENVIRONMENT}_{assignmentId}_sandbox_
              {isSandbox ? "true" : "false"}
            </p>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <div>
            <IconButton
              aria-label="help"
              onClick={() => setIsHelpModalOpen(true)}
              style={{ background: "#757ce8" }}
            >
              <QuestionMarkOutlined style={{ color: "#fff" }} />
            </IconButton>
          </div>
          <div>
            <Button
              variant="contained"
              color="primary"
              size="medium"
              startIcon={<ImageSearch />}
              onClick={() => setIsFunctionalQuestionModalOpen(true)}
              disabled={!metaData.length}
            >
              Preview
            </Button>
          </div>
          <div>
            <Button
              variant="contained"
              color="success"
              size="large"
              startIcon={<SaveIcon />}
              disabled={
                (isSaved && !metaData.find((m) => m.isStale)) ||
                !metaData.find((m) => m.isStale) ||
                !!validateMetaData(metaData ?? [])?.length
              }
              onClick={saveAllMetaData}
            >
              Save All
            </Button>
          </div>
          <div>
            <form id="mturk_form" action={mturkPostUrl} method="post">
              <input
                type="hidden"
                id="assignmentId"
                name="assignmentId"
                value={assignmentId}
              />
              <input
                type="hidden"
                id="assignment_id"
                name="assignment_id"
                value={assignment_id}
              />
              <Button
                variant="contained"
                color="success"
                size="large"
                startIcon={<SaveIcon />}
                disabled={
                  !isSaved ||
                  !!validationErrors?.length ||
                  !!metaData.find((m) => m.isStale)
                }
                type="submit"
              >
                Submit
              </Button>
            </form>
          </div>
        </div>
      </QuestionInfo>
      {isAdvancedMode && (
        <AnalyseSettings>
          <TextField
            name="threshold"
            type="number"
            value={editState.threshold}
            className={classes.textInput}
            label="Threshold"
            onChange={(e) =>
              setEditState(() => ({
                ...editState,
                threshold: e.target.value ?? 0,
              }))
            }
            inputProps={{ min: 0 }}
          />
          <TextField
            name="expandPixels"
            type="number"
            value={editState.expandPixels}
            className={classes.textInput}
            label="Expand Pixels"
            onChange={(e) =>
              setEditState(() => ({
                ...editState,
                expandPixels: e.target.value ?? 0,
              }))
            }
            inputProps={{ min: 0 }}
          />
          <TextField
            name="rectangleProximityThreshold"
            type="number"
            value={editState.rectangleProximityThreshold}
            className={classes.textInput}
            label="Rectangle Proximity"
            onChange={(e) =>
              setEditState(() => ({
                ...editState,
                rectangleProximityThreshold: e.target.value ?? 0,
              }))
            }
            inputProps={{ min: 0 }}
          />
          <TextField
            name="cropTopPixels"
            type="number"
            value={editState.cropTopPixels}
            className={classes.textInput}
            label="Crop Top Pixels"
            onChange={(e) =>
              setEditState(() => ({
                ...editState,
                cropTopPixels: e.target.value ?? 0,
              }))
            }
            inputProps={{ min: 0 }}
          />
          <TextField
            name="cropBottomPixels"
            type="number"
            value={editState.cropBottomPixels}
            className={classes.textInput}
            label="Crop Bottom Pixels"
            onChange={(e) =>
              setEditState(() => ({
                ...editState,
                cropBottomPixels: e.target.value ?? 0,
              }))
            }
            inputProps={{ min: 0 }}
          />
          <TextField
            name="minPixels"
            type="number"
            value={editState.minPixels}
            className={classes.textInput}
            label="Min Pixels (w - h)"
            onChange={(e) =>
              setEditState(() => ({
                ...editState,
                minPixels: e.target.value ?? 0,
              }))
            }
            inputProps={{ min: 0 }}
          />
          <Button
            variant="contained"
            color="primary"
            startIcon={<PageviewIcon />}
            disabled={
              metaData.filter((m) => m.metaDataId).length > 0 ||
              editState.isAnalysing
            }
            onClick={runAnalyse}
          >
            Analyse
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<RotateLeftIcon />}
            disabled={
              metaData.filter((m) => m.metaDataId).length > 0 ||
              metaData.length === 0 ||
              editState.isAnalysing
            }
            onClick={() => {
              setMetaData([]);
            }}
          >
            Reset
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<ImageSearch />}
            onClick={() => setIsPreviewModalOpen(true)}
          >
            Preview
          </Button>
        </AnalyseSettings>
      )}
      <ImageMetaDataShow
        defaultImageWidth={defaultImageWidth}
        insertMetaData={insertMetaData}
        updateMetaData={updateMetaData}
        handleDeletePress={handleDeletePress}
        setMetaData={setMetaData}
        metaData={getSortedMetaData()}
        editState={editState}
        setEditState={setEditState}
        isLoading={!editState.isLoaded || loadingQuestion}
        metaDataTags={metaDataTags}
      />
      <DeleteModal
        onClose={() =>
          setEditState(() => ({ ...editState, isDeleteModalOpen: false }))
        }
        isOpen={editState.isDeleteModalOpen}
        deleteMetaData={deleteMetaData}
      />
      <PreviewQuestionModal
        imageURL={imageURL}
        open={isPreviewModalOpen}
        onClose={() => setIsPreviewModalOpen(false)}
        bitmap={bitmap}
        metaData={metaData}
      />
      <HelpModal
        open={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
      />
      <LoadingModal open={isSavingModalOpen} />
      <FunctionalQuestionModal
        open={isFunctionalQuestionModalOpen}
        onClose={() => setIsFunctionalQuestionModalOpen(false)}
        metaData={metaData}
        metaDataTags={metaDataTags}
        question={question}
        imageURL={imageURL}
      />
      <Toasty />
    </StyledPage>
  );
};

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuestionInfo = styled.div`
  padding: 0.25rem 0 0.1rem 1rem;
  border-bottom: 1px solid #ccc;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  h5 {
    line-height: 3rem;
    margin: 0;
  }
  div {
    margin: 0 0.5rem 0 0;
  }
`;

const AnalyseSettings = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  padding: 1rem;
  border-bottom: 1px solid #ccc;
  * {
    margin-right: 0.5rem;
  }
`;

export default QuestionExtractorPage;
