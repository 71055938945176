import {
  Modal,
  Paper,
  FormGroup,
  TextField,
  Select,
  InputLabel,
  MenuItem,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  RenderAnswerImage,
  RenderAnswerText,
  RenderQuestionImage,
  RenderQuestionText,
} from "./RenderMetaDatas";
import { Form, Field } from "react-final-form";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#fff",
    border: "2px solid #000",
    boxShadow: "",
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    minWidth: "500px",
  },
  form: {
    borderTop: "1px solid #ccc",
    marginTop: "1rem",
    paddingTop: "1rem",
  },
  reviewStatus: {
    marginTop: "1rem",
  },
  buttons: {
    marginTop: "1rem",
  },
  createButton: {
    marginRight: "1rem",
  },
  cancelButton: {
    marginLeft: "1rem",
  },
}));

const MetaDataReviewModal = ({
  open,
  onClose,
  onChange = () => {},
  metaData,
  metaDataTag = null,
  question = null,
  index,
}) => {
  const classes = useStyles();

  const onSubmit = (values) => {
    onChange(index, "comment", values.comment);
    onChange(index, "reviewStatus", values.reviewStatus);
    onClose();
  };

  if (!metaData) return null;

  return (
    <Modal className={classes.modal} open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        <h3>Add Comment</h3>
        <div>
          <RenderQuestionImage metaData={metaData} metaDataTag={metaDataTag} />
          <RenderQuestionText metaData={metaData} metaDataTag={metaDataTag} />
          <RenderAnswerText
            metaData={metaData}
            metaDataTag={metaDataTag}
            question={question}
          />
          <RenderAnswerImage
            metaData={metaData}
            metaDataTag={metaDataTag}
            question={question}
          />
        </div>
        <Form
          onSubmit={onSubmit}
          initialValues={metaData ?? {}}
          validate={(values) => {
            const errors = {};
            if (values.comment?.length > 255) {
              errors.comment = "Comments must be less than 500 characters";
            }
            if (!values.comment) {
              errors.comment = "Please enter a comment";
            }
            return errors;
          }}
        >
          {({ handleSubmit, submitting, pristine, values, submitError }) => (
            <form onSubmit={handleSubmit} className={classes.form}>
              <FormGroup>
                <Field name="comment">
                  {({ input, meta }) => (
                    <TextField
                      {...input}
                      id="standard-basic"
                      minRows={4}
                      label="Comments"
                      variant="outlined"
                      className={classes.input}
                      error={meta.error && meta.touched}
                      helperText={
                        meta.error && meta.touched ? meta.error : null
                      }
                    />
                  )}
                </Field>
              </FormGroup>
              <FormGroup className={classes.reviewStatus}>
                <Field name="reviewStatus">
                  {({ input, meta }) => (
                    <>
                      <InputLabel
                        id="reviewStatus-label"
                        className={classes.select}
                      >
                        Review Status (optional)
                      </InputLabel>
                      <Select
                        {...input}
                        name="reviewStatus"
                        labelId="reviewStatus-label"
                        error={meta.error && meta.touched}
                      >
                        {[
                          { label: "Approved", value: "Approved" },
                          { label: "Failed", value: "Failed" },
                        ].map((r, idx) => (
                          <MenuItem key={idx} value={r.value}>
                            {r.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  )}
                </Field>
              </FormGroup>
              <div className={classes.buttons}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  className={classes.createButton}
                  startIcon={<SaveIcon />}
                  disabled={submitting || pristine || submitError}
                >
                  Done
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  className={classes.cancelButton}
                  onClick={() => onClose()}
                  startIcon={<CancelIcon />}
                  disabled={submitting}
                >
                  Cancel
                </Button>
              </div>
            </form>
          )}
        </Form>
      </Paper>
    </Modal>
  );
};

export default MetaDataReviewModal;
