import { DeleteForever } from "@mui/icons-material";
import { Button, Card, CardContent, Modal } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

const DeleteModal = ({ onClose, isOpen, deleteMetaData = () => {} }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card>
        <CardContent
          style={{
            textAlign: "center",
            minWidth: "400px",
            minHeight: "150px",
          }}
        >
          <h3>Are you sure you want to delete metadata?</h3>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<DeleteForever />}
              onClick={deleteMetaData}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              startIcon={<CancelIcon />}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default DeleteModal;
