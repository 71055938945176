import jsfeat from 'jsfeat';
import Tesseract from 'tesseract.js';

export const scaleCalc = (sourceWidth, adjustedWidth, x, scale) =>
  (x / scale) * (adjustedWidth / sourceWidth);

export const scaleCalcAll = (
  sourceWidth,
  adjustedWidth,
  x,
  y,
  width,
  height,
  scale
) => {
  const scaledX = scaleCalc(sourceWidth, adjustedWidth, x, scale);
  const scaledY = scaleCalc(sourceWidth, adjustedWidth, y, scale);
  const scaledWidth = scaleCalc(sourceWidth, adjustedWidth, width, scale);
  const scaledHeight = scaleCalc(sourceWidth, adjustedWidth, height, scale);
  return { x: scaledX, y: scaledY, width: scaledWidth, height: scaledHeight };
};

export const checkOverlap = (x, y, width, height, rectangles) => {
  for (let i = 0; i < rectangles.length; i++) {
    const rect = rectangles[i];

    if (
      x < rect.x + rect.width &&
      x + width > rect.x &&
      y < rect.y + rect.height &&
      y + height > rect.y
    ) {
      return true;
    }
  }

  return false;
};

// get overlap percentage for two retangles
export const getOverlapPercentage = (rect1, rect2) => {
  // Find the x, y coordinates of the intersection rectangle
  let xOverlap = Math.max(
    0,
    Math.min(rect1.x + rect1.width, rect2.x + rect2.width) -
      Math.max(rect1.x, rect2.x)
  );
  let yOverlap = Math.max(
    0,
    Math.min(rect1.y + rect1.height, rect2.y + rect2.height) -
      Math.max(rect1.y, rect2.y)
  );

  // Calculate the area of the intersection rectangle
  let intersectionArea = xOverlap * yOverlap;

  // Calculate the area of the smaller rectangle
  let rect1Area = rect1.width * rect1.height;
  let rect2Area = rect2.width * rect2.height;
  let smallerArea = Math.min(rect1Area, rect2Area);

  // Calculate the percentage of overlap
  let overlapPercentage = (intersectionArea / smallerArea) * 100;

  return overlapPercentage;
};

export const loadImageBitmap = async imageURL => {
  const image = new Image();
  image.crossOrigin = 'anonymous';
  image.src = imageURL;
  const resp = await fetch(image.src);
  if (!resp.ok) {
    return console.error('Network error', resp.status);
  }
  const blob = await resp.blob();
  return await createImageBitmap(blob);
};

export const cropImage = async ({ bitmap, x, y, width, height, scale }) => {
  try {
    x = x / scale;
    y = y / scale;
    width = width / scale;
    height = height / scale;

    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');
    context.drawImage(bitmap, x, y, width, height, 0, 0, width, height);

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error('Failed to create blob.'));
        }
      }, 'image/png');
    });
  } catch (error) {
    console.error(error);
  }
};

export const cropImageToImage = async ({
  bitmap,
  x,
  y,
  width,
  height,
  scale = 1
}) => {
  try {
    // Resize the bitmap
    const resizedCanvas = document.createElement('canvas');
    resizedCanvas.width = bitmap.width * scale;
    resizedCanvas.height = bitmap.height * scale;
    const resizedContext = resizedCanvas.getContext('2d');
    resizedContext.drawImage(
      bitmap,
      0,
      0,
      resizedCanvas.width,
      resizedCanvas.height
    );

    // Crop the resized bitmap
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');
    context.drawImage(resizedCanvas, x, y, width, height, 0, 0, width, height);

    const image = new Image();
    image.src = canvas.toDataURL('image/png');
    return new Promise(resolve => {
      image.onload = () => {
        resolve(image);
      };
    });
  } catch (error) {
    console.error(error);
  }
};

export const tesseractOCR = async ({ metaData = [], lang = 'eng' }) => {
  if (!metaData?.length) return metaData;

  const promises = [];

  metaData.forEach(m => {
    const promise = new Promise(async resolve => {
      const job = await Tesseract.recognize(m.blob, lang);
      resolve({ ...m, text: job?.data?.text?.trim() ?? '' });
    });
    promises.push(promise);
  });

  return await Promise.all(promises);
};

export const combineImageBlobs = async (imageBlobs = []) => {
  if (!imageBlobs?.length) return null;

  const images = [];
  const promises = [];
  const dimensions = [];

  imageBlobs.forEach((blob, i) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    const url = URL.createObjectURL(blob);
    const promise = new Promise(resolve => {
      image.onload = () => {
        dimensions.push({ index: i, width: image.width, height: image.height });
        resolve();
      };
    });
    image.src = url;
    images.push({ index: i, image });
    promises.push(promise);
  });

  await Promise.all(promises);

  const maxWidth = dimensions.reduce((max, obj) => {
    return obj.width > max ? obj.width : max;
  }, -Infinity);

  // sum heights
  const totalHeight = dimensions.reduce((sum, obj) => {
    return sum + obj.height;
  }, 0);

  const canvas = document.createElement('canvas');
  canvas.width = maxWidth;
  canvas.height = totalHeight;
  const ctx = canvas.getContext('2d');
  ctx.fillStyle = 'white'; // Set the background color to white
  ctx.fillRect(0, 0, canvas.width, canvas.height); // Fill the canvas with white

  let setHeight = 0;
  images.forEach(i => {
    ctx.drawImage(i.image, 0, setHeight);
    setHeight += dimensions[i.index].height;
  });
};

export const detectROIs = async ({
  imageURL,
  threshold = 20,
  rectangleProximityThreshold = 10,
  expandPixels = 10,
  cropTopPixels = 0,
  cropBottomPixels = 0,
  minPixels,
  bitmap,
  scale = 1
}) => {
  threshold = Math.round(threshold / scale);
  rectangleProximityThreshold = Math.round(rectangleProximityThreshold / scale);
  expandPixels = Math.round(expandPixels / scale);
  cropTopPixels = Math.round(cropTopPixels / scale);
  cropBottomPixels = Math.round(cropBottomPixels / scale);

  // https://inspirit.github.io/jsfeat/#features2d
  const image = new Image();
  image.crossOrigin = 'anonymous';
  image.src = imageURL;
  await new Promise(resolve => {
    image.onload = resolve;
  });
  const canvas = document.createElement('canvas');

  const ctx = canvas.getContext('2d');
  canvas.width = image.width;
  canvas.height = image.height;

  // https://stackoverflow.com/questions/26015497/how-to-resize-then-crop-an-image-with-canvas
  ctx.drawImage(image, 0, 0);

  // draw a white rectangle as wide as the image and as high as the top crop at the top of the canvas
  ctx.fillStyle = '#FFFFFF';
  ctx.fillRect(0, 0, canvas.width, cropTopPixels);

  // draw a white rectangle as wide as the image and as high as the bottom crop at the bottom of the canvas
  ctx.fillStyle = '#FFFFFF';
  ctx.fillRect(
    0,
    canvas.height - cropBottomPixels,
    canvas.width,
    cropBottomPixels
  );

  // output the canvas as a data URL
  const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

  const grayImg = new jsfeat.matrix_t(
    canvas.width,
    canvas.height,
    jsfeat.U8_t | jsfeat.C1_t
  );

  jsfeat.imgproc.grayscale(
    imageData.data,
    canvas.width,
    canvas.height,
    grayImg
  );

  jsfeat.imgproc.equalize_histogram(grayImg, grayImg);

  const max_corners = 1000;
  const corners = new Array(max_corners);
  for (let i = 0; i < grayImg.cols * grayImg.rows; ++i) {
    corners[i] = new jsfeat.keypoint_t(0, 0, 0, 0);
  }

  const border = 3;

  jsfeat.fast_corners.set_threshold(threshold);
  const count = jsfeat.fast_corners.detect(grayImg, corners, border);

  // Find corners that are within proximity of each other
  // Define proximity threshold
  const proximityThreshold = 10;
  const cornerGroups = [];

  for (let i = 0; i < count; i++) {
    const corner1 = corners[i];
    let groupIndex = -1;
    for (let j = 0; j < cornerGroups.length; j++) {
      const corner2 = corners[cornerGroups[j][0]];
      const distance = Math.sqrt(
        (corner1.x - corner2.x) ** 2 + (corner1.y - corner2.y) ** 2
      );
      if (distance < proximityThreshold) {
        groupIndex = j;
        break;
      }
    }
    if (groupIndex === -1) {
      groupIndex = cornerGroups.length;
      cornerGroups.push([]);
    }
    cornerGroups[groupIndex].push(i);
  }

  const rois = [];
  let rectangleId = 0;

  for (const group of cornerGroups) {
    if (group.length > 1) {
      let minX = Number.MAX_SAFE_INTEGER;
      let minY = Number.MAX_SAFE_INTEGER;
      let maxX = Number.MIN_SAFE_INTEGER;
      let maxY = Number.MIN_SAFE_INTEGER;
      for (const index of group) {
        const corner = corners[index];
        minX = Math.min(minX, corner.x);
        minY = Math.min(minY, corner.y);
        maxX = Math.max(maxX, corner.x);
        maxY = Math.max(maxY, corner.y);
      }
      const width = maxX - minX;
      const height = maxY - minY;

      rois.push({
        x: minX,
        y: minY,
        width,
        height,
        scale: 1,
        opacity: 0.5,
        rectangleId: rectangleId++,
        hexColor: '#000000'
      });
    }
  }

  const expandedRectangles = expandRectangles(rois, expandPixels);
  const output = mergeOverlappingRectangles(
    detectProximity(expandedRectangles, rectangleProximityThreshold)
  ).filter(r => r.width > minPixels || r.height > minPixels);

  for (let i = 0; i < output.length; i++) {
    const r = output[i];
    r.isStale = true;
    r.blob = await cropImage({ ...r, bitmap });
  }

  return output;
};

function detectProximity(rectangles, threshold) {
  const proximityGroups = [];

  for (let i = 0; i < rectangles.length; i++) {
    const rectangle1 = rectangles[i];
    const center1 = {
      x: rectangle1.x + rectangle1.width / 2,
      y: rectangle1.y + rectangle1.height / 2
    };

    // check if rectangle1 is in proximity to any existing group
    let groupIndex = -1;
    for (let j = 0; j < proximityGroups.length; j++) {
      const groupCenter = proximityGroups[j].center;
      const distance = Math.sqrt(
        Math.pow(center1.x - groupCenter.x, 2) +
          Math.pow(center1.y - groupCenter.y, 2)
      );
      if (distance <= threshold) {
        groupIndex = j;
        break;
      }
    }

    // add rectangle1 to an existing group or create a new group
    if (groupIndex >= 0) {
      proximityGroups[groupIndex].rectangles.push(rectangle1);
      proximityGroups[groupIndex].center.x =
        (proximityGroups[groupIndex].center.x + center1.x) / 2;
      proximityGroups[groupIndex].center.y =
        (proximityGroups[groupIndex].center.y + center1.y) / 2;
    } else {
      proximityGroups.push({
        center: center1,
        rectangles: [rectangle1]
      });
    }
  }

  // output x, y, width, height for each proximity group
  const proximityRectangles = [];
  let rectangleId = 0;
  for (const group of proximityGroups) {
    let minX = Number.MAX_SAFE_INTEGER;
    let minY = Number.MAX_SAFE_INTEGER;
    let maxX = Number.MIN_SAFE_INTEGER;
    let maxY = Number.MIN_SAFE_INTEGER;
    for (const rectangle of group.rectangles) {
      minX = Math.min(minX, rectangle.x);
      minY = Math.min(minY, rectangle.y);
      maxX = Math.max(maxX, rectangle.x + rectangle.width);
      maxY = Math.max(maxY, rectangle.y + rectangle.height);
    }
    proximityRectangles.push({
      x: minX,
      y: minY,
      width: maxX - minX,
      height: maxY - minY,
      scale: 1,
      opacity: 0.5,
      rectangleId: rectangleId++,
      hexColor: '#000000'
    });
  }

  return proximityRectangles;
}

function expandRectangles(rectangles, expandBy) {
  const expandedRectangles = [];

  for (let i = 0; i < rectangles.length; i++) {
    const r = rectangles[i];
    const centerX = r.x + r.width / 2;
    const centerY = r.y + r.height / 2;
    const expandedWidth = r.width + expandBy * 2;
    const expandedHeight = r.height + expandBy * 2;
    const expandedX = centerX - expandedWidth / 2;
    const expandedY = centerY - expandedHeight / 2;
    expandedRectangles.push({
      ...r,
      x: expandedX,
      y: expandedY,
      width: expandedWidth,
      height: expandedHeight
    });
  }

  return expandedRectangles;
}

function mergeOverlappingRectangles(rectangles, depth = 0) {
  // Find the first rectangle that has overlapping rectangles
  const r1 = rectangles.find(r1 => {
    return rectangles.some(r2 => r1 !== r2 && rectanglesOverlap(r1, r2));
  });

  if (!r1) {
    // No more overlapping rectangles to merge
    return rectangles;
  }

  // Find all rectangles that overlap with r1
  const overlappingRectangles = rectangles.filter(r2 => {
    return r1 !== r2 && rectanglesOverlap(r1, r2);
  });

  // Merge all overlapping rectangles into r1
  overlappingRectangles.forEach(r2 => {
    r1.x = Math.min(r1.x, r2.x);
    r1.y = Math.min(r1.y, r2.y);
    r1.width = Math.max(r1.x + r1.width, r2.x + r2.width) - r1.x;
    r1.height = Math.max(r1.y + r1.height, r2.y + r2.height) - r1.y;
  });

  // Remove overlapping rectangles from the array
  const remainingRectangles = rectangles.filter(r2 => {
    return !overlappingRectangles.includes(r2);
  });

  // Recursively merge any remaining overlapping rectangles
  let mergedRectangles = mergeOverlappingRectangles(
    [...remainingRectangles, r1],
    depth + 1
  );

  if (depth === 0) {
    mergedRectangles = mergedRectangles
      .filter(
        (rect, index, self) =>
          index ===
          self.findIndex(
            r =>
              r.x === rect.x &&
              r.y === rect.y &&
              r.width === rect.width &&
              r.height === rect.height
          )
      )
      .sort((a, b) => a.x - b.x || a.y - b.y)
      .map((r, i) => ({ ...r, rectangleId: i }));
  }

  return mergedRectangles;
}

export function rectanglesOverlap(r1, r2) {
  return (
    r1.x + r1.width > r2.x &&
    r1.x < r2.x + r2.width &&
    r1.y + r1.height > r2.y &&
    r1.y < r2.y + r2.height
  );
}

function isRectangleOverlap(rect1, rect2) {
  const xOverlap = Math.max(
    0,
    Math.min(rect1.x + rect1.width, rect2.x + rect2.width) -
      Math.max(rect1.x, rect2.x)
  );
  const yOverlap = Math.max(
    0,
    Math.min(rect1.y + rect1.height, rect2.y + rect2.height) -
      Math.max(rect1.y, rect2.y)
  );
  return xOverlap * yOverlap > 0;
}

// Function to detect rectangles in a row
export function findRectangles(
  rectangles,
  boundingBox = {
    x: 0,
    y: 0,
    width: 100,
    height: 100
  }
) {
  return (
    rectangles
      .filter(a => isRectangleOverlap(a, boundingBox))
      // sort by rows and columns
      .sort((a, b) => a.y - b.y || a.x - b.x)
  );
}
