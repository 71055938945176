const settings = {
  ENVIRONMENT: 'prod',
  API_URL:
    'prod' === "qa"
      ? "https://api-qa.eedi.com"
      : 'prod' === "prod"
      ? "https://api.eedi.com"
      : process.env.REACT_APP_API_URL
      ? process.env.REACT_APP_API_URL
      : "https://api-qa.eedi.com",
  FLOW_API_URL:
    'prod' === "qa"
      ? "https://flow-api-qa.eedi.com"
      : 'prod' === "prod"
      ? "https://flow-api.eedi.com"
      : process.env.REACT_APP_FLOW_API_URL
      ? process.env.REACT_APP_FLOW_API_URL
      : "https://flow-api-qa.eedi.com",
  API_NINJA_KEY: "K7Xz/C4vcSPgz+xK8imYgw==E4sApC3eRTa8KTfg",
  GOOGLE_AUTH_CLIENT_ID:
    "250963904963-e2uegfjodagts71r9lmv58j3ablqv6c6.apps.googleusercontent.com",
  MATHPIX_API_KEY: '07052ac851bdbd85ba6130e73f5cc3415b7caaabb4bab6ffd59b18760e3309a7',
  MATHPIX_APP_ID: 'invoices_eedi_co_uk_cd4399_4ba047',
};

export default settings;
