import querystring from "querystring-es3";
import trim from "lodash/trim";

const getRequest = (method, url, data, token, settings) => {
  const uriToken = new URLSearchParams(window.location.search).get("token");
  if (uriToken) {
    token = uriToken;
  }
  const extraHeaders = settings?.headers ? settings.headers : {};
  const headerValues = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Source: `Eedi-Admin`,
    "X-Is-Trackable": "false",
    "Access-Control-Allow-Private-Network": "true",
    ...extraHeaders,
  };

  if (extraHeaders.isFormData) {
    delete headerValues.Accept;
    delete headerValues["Content-Type"];
  }

  const headers = new Headers(headerValues);

  if (token && !settings?.disableToken) {
    headers.append("Authorization", `Bearer ${token}`);
  }

  let req = {
    method: method.toUpperCase(),
    headers,
  };
  // If this is a get request append data on to the query string
  if (data || headerValues["isFormData"]) {
    if (method === "GET") {
      url += "?" + querystring.stringify(data);
    } else if (headerValues["isFormData"]) {
      req["body"] = data;
    } else {
      req["body"] = JSON.stringify(data);
    }
  }

  return new Request(url, req);
};

const request = async (method, url, body, settings, timeoutMs = 6000000) => {
  const request = getRequest(
    method,
    url,
    body,
    localStorage.getItem("authToken"),
    settings
  );

  let timeoutPromise =
    timeoutMs > 0
      ? new Promise((resolve, reject) =>
          setTimeout(
            () =>
              reject(new Error("Your request timed out.  Please try again.")),
            timeoutMs
          )
        )
      : null;

  let fetchPromise = new Promise((resolve, reject) => {
    if (!method) {
      throw new TypeError("Invalid Request Method");
    }

    if (!url) {
      throw new TypeError("Invalid Request Endpoint");
    }

    return fetch(request)
      .then(handleValidResponse(settings))
      .then(checkResponseForErrors)
      .then((responseBody) => resolve(responseBody))
      .catch((e) => {
        e.domain = "Client.fetcher.fetch";
        e.data = { url, body };
        e.code = e.status_code;
        reject(e);
      });
  });

  if (!timeoutPromise) {
    return fetchPromise;
  }

  return Promise.race([fetchPromise, timeoutPromise]);
};

const handleValidResponse = (settings) => (response) => {
  if (response.status === 401) {
    localStorage.removeItem("authToken");
    localStorage.removeItem("user");
    return {};
  }

  try {
    if (response.status === 204) {
      return {};
    }

    switch (settings?.readBodyAs) {
      case "text":
        return response.text();
      case "blob":
        return response.blob();
      case "formData":
        return response.formData();
      case "arrayBuffer":
        return response.arrayBuffer();
      case "file":
        return saveResponseAsFile(response);

      case "json":
        return response.json();

      case "response":
        return response;

      default:
        return response.json().catch((e) => {
          console.log(e);
          return {};
        });
    }
  } catch (e) {
    throw new SyntaxError("Improper Response Received");
  }
};

const checkResponseForErrors = (response) => {
  if (!response) return;
  const error = response.message || response.error;
  if (error || response.statusCode >= 400) {
    if (response.modelState) {
      let errors = "";
      Object.entries(response?.modelState ?? {})?.forEach((e) => {
        errors += ` ${e}`;
      });
      throw new Error(errors);
    }

    throw new Error(error);
  }

  return response;
};

const saveResponseAsFile = async (response) => {
  const header = response.headers.get("content-disposition") ?? "";

  const parts = header.split(";");

  const fileName = parts
    .find((part) => part.includes("filename="))
    ?.split("=")[1];

  if (!response.ok || (!header && !fileName)) {
    throw new Error("Error downloading file");
  }

  // Filename may include double quotes for some reason
  const cleanedFileName = trim(fileName, '  "');

  saveFile(await response.blob(), cleanedFileName);
};

const saveFile = (blob, filename) => {
  // IE11 fallback
  if (window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
    return;
  }

  const url = window.URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();

  // Fixes issue in Firefox 52.0 and Edge where the file will not download at all.
  setTimeout(() => {
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }, 0);
};

export class FormError extends Error {
  constructor(message, modelState) {
    super(message);

    let formattedModelState = {};

    for (const [key, error] of Object.entries(modelState)) {
      if (error) {
        formattedModelState[key] = { message: error };
      }
    }
    this.modelState = formattedModelState;

    // a workaround to make `instanceof` work in ES5 https://github.com/babel/babel/issues/3083
    this.constructor = FormError;
    this.__proto__ = FormError.prototype;

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, FormError);
    }
  }
}

const client = {
  request,
};

export default client;
