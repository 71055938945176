import { Modal, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Layer, Rect, Stage, Group } from "react-konva";
import { cropImageToImage, scaleCalc } from "../../../helpers/imageHelpers";

const previewWidth = 900;

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "fff",
    border: "2px solid #000",
    boxShadow: null,
    padding: "1rem",
    display: "flex",
    flexDirection: "row",
  },
  image: {
    width: `${previewWidth}px`,
    height: "auto",
    background: "#ddd",
  },
}));

const PreviewQuestionModal = ({
  imageURL,
  open,
  onClose,
  metaData = [],
  bitmap,
}) => {
  const classes = useStyles();
  const [blobImages, setBlobImages] = useState([]);

  useEffect(() => {
    if (!bitmap || !imageURL) return;
    const imagePromises = metaData
      ?.filter((m) => !!m.metaDataTagId)
      ?.map((rect) => {
        const scale = previewWidth / bitmap.width;
        return cropImageToImage({
          bitmap,
          x: scaleCalc(bitmap.width, previewWidth, rect.x, rect.scale),
          y: scaleCalc(bitmap.width, previewWidth, rect.y, rect.scale),
          width: scaleCalc(bitmap.width, previewWidth, rect.width, rect.scale),
          height: scaleCalc(
            bitmap.width,
            previewWidth,
            rect.height,
            rect.scale
          ),
          scale,
        });
      });

    Promise.all(imagePromises).then((blobs) => {
      setBlobImages(blobs);
    });
  }, [bitmap, bitmap.width, imageURL, metaData]);

  if (!bitmap || !imageURL) return null;

  return (
    <Modal className={classes.modal} open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        <div>
          <p>Source</p>
          <img
            src={imageURL}
            className={classes.image}
            alt="Question Preview"
          />
        </div>

        <div style={{ background: "#ddd" }}>
          <p>Preview - will show tagged metadata only</p>
          <Stage width={previewWidth} height={(previewWidth / 4) * 3}>
            <Layer>
              {metaData
                ?.filter((m) => !!m.metaDataTagId)
                ?.map((rect, i) => (
                  <Group
                    key={`${i}-${rect.rectangleId}`}
                    x={scaleCalc(
                      bitmap.width,
                      previewWidth,
                      rect.x,
                      rect.scale
                    )}
                    y={scaleCalc(
                      bitmap.width,
                      previewWidth,
                      rect.y,
                      rect.scale
                    )}
                  >
                    <Rect
                      width={scaleCalc(
                        bitmap.width,
                        previewWidth,
                        rect.width,
                        rect.scale
                      )}
                      height={scaleCalc(
                        bitmap.width,
                        previewWidth,
                        rect.height,
                        rect.scale
                      )}
                      fillPatternImage={blobImages[i]}
                      fillPatternRepeat="no-repeat"
                      opacity={1}
                    />
                  </Group>
                ))}
            </Layer>
          </Stage>
        </div>
      </Paper>
    </Modal>
  );
};

export default PreviewQuestionModal;
