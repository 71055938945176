import { IconButton, Modal, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import Latex from "../../../helpers/latexify";
import styled from "styled-components";
import { LatexTable } from "../../../helpers/latexHelpers";
import CancelIcon from "@mui/icons-material/Cancel";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: "#fff",
    border: "2px solid #000",
    boxShadow: null,
    padding: "1rem",
    display: "flex",
    flexDirection: "column",
    maxHeight: "800px",
    overflowY: "scroll",
  },
}));

const RenderQuestionImage = ({ metaData, metaDataTag }) => {
  if (metaDataTag?.label !== "Question Image") return null;

  return (
    <div style={{ textAlign: "center" }}>
      <QuestionImage
        src={URL.createObjectURL(metaData.blob)}
        alt="Question Image"
      />
    </div>
  );
};

const RenderQuestionText = ({ metaData, metaDataTag }) => {
  if (metaDataTag?.label !== "Question Text") return null;

  return (
    <QuestionText style={{ whiteSpace: "pre-line" }}>
      {LatexTable(metaData.text) || (
        <Latex output="mathml" displayMode={false}>
          {metaData.text}
        </Latex>
      )}
    </QuestionText>
  );
};

const RenderAnswerText = ({ metaData, metaDataTag, question }) => {
  const answerTypes = [
    "",
    "Answer A Text",
    "Answer B Text",
    "Answer C Text",
    "Answer D Text",
  ];
  if (!answerTypes.includes(metaDataTag?.label) || !metaData.text) return null;
  return (
    <AnswerText
      iscorrect={
        !!(answerTypes.indexOf(metaDataTag?.label) === question?.correctAnswer)
      }
    >
      <AnswerLabel bgcolor={metaDataTag?.hexColor}>
        {metaDataTag?.label?.split(" ")[1] || "#"}
      </AnswerLabel>
      <div style={{ whiteSpace: "pre-line" }}>
        {metaData?.text ? (
          <Latex output="mathml" displayMode={false}>
            {metaData.text}
          </Latex>
        ) : (
          <>-</>
        )}
      </div>
    </AnswerText>
  );
};

const RenderAnswerImage = ({ metaData, metaDataTag, question }) => {
  const answerTypes = [
    "",
    "Answer A Image",
    "Answer B Image",
    "Answer C Image",
    "Answer D Image",
  ];
  if (!answerTypes.includes(metaDataTag?.label)) return null;
  return (
    <AnswerText
      iscorrect={
        !!(answerTypes.indexOf(metaDataTag?.label) === question?.correctAnswer)
      }
    >
      <AnswerLabel bgcolor={metaDataTag?.hexColor}>
        {metaDataTag?.label?.split(" ")[1] || "#"}
      </AnswerLabel>
      <div>
        <AnswerImage
          src={URL.createObjectURL(metaData.blob)}
          alt="Answer Image"
        />
      </div>
    </AnswerText>
  );
};

const FunctionalQuestionModal = ({
  open,
  onClose = () => {},
  metaData = [],
  question = {},
  metaDataTags = [],
  imageURL = "",
}) => {
  const classes = useStyles();

  if (!open) return null;

  return (
    <Modal className={classes.modal} open={open} onClose={onClose}>
      <Paper className={classes.paper}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center", width: "100%" }}>
            <h3>Question Preview - {question.questionId}</h3>
          </div>
          <div>
            <IconButton onClick={onClose} aria-label="close" size="large">
              <CancelIcon />
            </IconButton>
          </div>
        </div>

        <PreviewWrapper>
          <div
            style={{
              padding: "0.75rem 0.75rem 0.75rem 0",
              alignItems: "flex-start",
            }}
          >
            {!!imageURL && <img src={imageURL} alt="Question" />}
          </div>
          <div style={{ padding: "0.75rem" }}>
            {metaData?.filter((m) => !!m.metaDataTagId && !!m.text).length ===
              0 && (
              <>
                <h2>No metadata has been tagged and annotated!</h2>
                <h4>
                  Draw bounding boxes and applies labels to complete tagging.
                </h4>
              </>
            )}
            {metaData
              .sort((a, b) => a.sequence - b.sequence)
              .map((m) => {
                const tag = metaDataTags?.find(
                  (mt) => mt.metaDataTagId === m.metaDataTagId
                );
                return (
                  <React.Fragment key={m.rectangleId}>
                    <RenderQuestionImage metaData={m} metaDataTag={tag} />
                    <RenderQuestionText metaData={m} metaDataTag={tag} />
                    <RenderAnswerText
                      metaData={m}
                      metaDataTag={tag}
                      question={question}
                    />
                    <RenderAnswerImage
                      metaData={m}
                      metaDataTag={tag}
                      question={question}
                    />
                  </React.Fragment>
                );
              })}
          </div>
        </PreviewWrapper>
      </Paper>
    </Modal>
  );
};

const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  img {
    width: 480px;
    height: 360px;
    border: 1px solid #ccc;
  }
`;

const QuestionImage = styled.img`
  max-width: 450px;
  width: auto !important;
  height: auto !important;
  margin: 0 auto;
`;

const AnswerImage = styled.img`
  max-width: 250px;
  width: auto !important;
  height: 125px !important;
`;

const QuestionText = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0 0 0.5rem 0;
  text-align: center;
`;

const AnswerText = styled.div`
  font-size: 1.25rem;
  font-weight: normal;
  margin: 1rem 0 1rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${(props) =>
    !!props.iscorrect &&
    `
      background: #E0FDD6;
      padding: 0.2rem;
      border-radius: 8px;
      border: 1px solid lightgreen;
    `}

  div {
    margin: 0 0.5rem 0 0;
  }
`;

const AnswerLabel = styled.div`
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${(props) =>
    props.bgcolor ?? "#ccc"}; /* Replace with your desired background color */
  color: #ffffff;
  text-align: center;
  font-size: 24px;
  line-height: 50px;
`;

export default FunctionalQuestionModal;
